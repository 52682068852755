<script setup lang="ts">
import { debounce } from 'perfect-debounce'

const emit = defineEmits(['close'])

const search = ref('')

const debouncedUpdate = debounce(() => {
  if (search.value.length > 1) {
    refreshProductLines()
    refreshProductSets()
  }
}, 300)

watch(search, () => {
  debouncedUpdate()
})

const searchRepository = createSearchRepository()

const {
  data: productLines,
  status: productLinesStatus,
  refresh: refreshProductLines,
} = await useLazyAsyncData(
  'searchProductLines',
  () => searchRepository.searchProductLines(search.value),
  {
    immediate: false,
  }
)

const {
  data: productSets,
  status: productSetsStatus,
  refresh: refreshProductSets,
} = await useLazyAsyncData(
  'searchProductSets',
  () => searchRepository.searchProductSets(search.value),
  {
    immediate: false,
  }
)

const noProductLines = computed(() => {
  return (productLines.value?.items ?? []).length === 0
})

const noProductSets = computed(() => {
  return (productSets.value?.items ?? []).length === 0
})

const noSearchResults = computed(() => {
  return noProductLines.value && noProductSets.value

  // TODO add references and fulltext check
})

function close() {
  emit('close')
}

const route = useRoute()

watch(route, () => {
  close()
})
</script>

<template>
  <div>
    <form class="mx-auto" @submit.prevent>
      <div class="flex w-full">
        <UInput
          v-model="search"
          :ui="{
            width: 'w-full left-0 !-ml-[8px] rounded-0 py-2',
            shadow: '',
            ring: '',
            rounded: '',
            size: {
              lg: 'text-lg',
            },
          }"
          autofocus
          color="primary"
          class="w-full border-black"
          size="lg"
          :placeholder="$t('base.search.placeholder')"
        />
      </div>
    </form>
    <div
      class="z-50 max-h-[calc(100svh-6rem)] w-full overflow-x-auto pt-5 lg:absolute lg:left-0 lg:top-40 lg:max-h-[calc(100svh-10rem)] lg:w-full lg:pt-0"
    >
      <div class="mb-[4px] w-full border-b-4 border-white bg-neutral-100">
        <div
          class="font-pragmatica-ext container w-full overflow-hidden py-10 text-xl font-bold lg:py-16"
        >
          <div
            v-if="
              (productLinesStatus === 'pending' ||
                productSetsStatus === 'pending') &&
              search.length > 1
            "
            class="text-center"
          >
            {{ $t('base.search.searching') }}
          </div>
          <div v-else-if="search.length < 2" class="text-center">
            {{ $t('base.search.minimalLength') }}
          </div>
          <div v-else-if="noSearchResults" class="text-center">
            {{ $t('base.search.empty') }}
          </div>
          <template v-else>
            <SearchProductLines
              v-if="!noProductLines"
              :product-lines="productLines"
              short
              @link-clicked="close()"
            />
            <SearchProductSets
              v-if="!noProductSets"
              :product-sets="productSets"
              short
              @link-clicked="close()"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
