import revive_payload_client_Q1zui0kqrgscIO9JuWbDGd96T_xoC4bmf1R5x16dTZ0 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nvl_6h_zALiVGsOntgnFrQGLH7WbVi52YylmpQKthvY from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WhPogIimpZhnFcb13UXjrHfiTPwVljEi_N6SPol4sGc from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_g7bR4LcgqJNxeTkCjHNUesiI96lbXDWGXsf_tkY0H7A from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_36099QVCT1dyZn230ABc_iWTnxuqDvJ45HYgibQSdxc from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cofTII8VLlslUvJjCRU13U4OFIUEXK4UZMkw58qt_ac from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_p0_NtiUQm0R_TizqEqZ10BDOLWfkMQRov314CqOP7w0 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/ldseating/ldseating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client__nkeDhMiIOTRi9G4mJQEdfK0ld70mVWal4BT2_lzhNo from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nmEZsL6woq_99dOYLwIANJn6dURJtYM5CDpeuRUWIbc from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_client_unwbSlAJh6MovdrTGHeZT6yHRTE_s4SkmevwiKbqliA from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import slideovers_rBeH_l_W8_9oFpgqXGxIbYEBE_XeDRcz6hrfTO9aggM from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.21.1_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2.4_@typ_4gpxbhrt6k3ywirlyqjgewuxjy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_TLLiDLNceFzZlyV_8K9HQHoIJWVy8XNSsT_ueAtKUtw from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.21.1_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2.4_@typ_4gpxbhrt6k3ywirlyqjgewuxjy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_wstRWiKwhigqn4lOWAETagzOzpFn5ByHAaJo0W_CM9o from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+ui@2.21.1_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2.4_@typ_4gpxbhrt6k3ywirlyqjgewuxjy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_x7ErcjVqPG507X9B7TARgWnEOyuSD3GVRAN7UATfcnw from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@18.19.85_jiti@2.4.2_sass@1.86.1_terse_h7mygpsgfmbgk54xuney4kzo2y/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import siteConfig_1q71eE_kXhO3rtT7EifVerkS1F3iJSjMFjFsGlCJxdM from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin__rygPpTQTHD6ZwSV_Pxl78zXBS_RcSFU9gDuv2HYrDs from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_f7SMVJUHee5kXp7l0HP_qLKf0r_SzTKAWIZG0Aa0QrE from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_WHL63kyutrL1KokBLzQ6hPP8dAmuF7HhKDtmrqYJVyU from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_o0tunxvYgtkZ_rniqpraeAu4gKWSs22WMG3vqjfgWuw from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_kPZiu5t37WxV9hrlElTF8BRy3io9HhsgppGCLuEM5CU from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_olN9U9w58EtSfjVnmP5F214841AKWqne_1Bpwa_UjWY from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_QeCAqS5j7aNHBRWK1O6s70paWD_vaV2bKTipXYcQ7Yw from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_qzx5i7csrejithlmbxaugrizf4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/builds/ldseating/ldseating-frontend/plugins/api.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/builds/ldseating/ldseating-frontend/plugins/sentry.client.ts";
import _0_routeRules_fqnkpUHFM4B2PIWwpSQ1TDoOJQzXJ9Gi5qh_QetP2GQ from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_5q2wA_8rlMfFQRTUZLPuilXcKnulSek1qzlYTjLiDDw from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_Q1zui0kqrgscIO9JuWbDGd96T_xoC4bmf1R5x16dTZ0,
  unhead_nvl_6h_zALiVGsOntgnFrQGLH7WbVi52YylmpQKthvY,
  router_WhPogIimpZhnFcb13UXjrHfiTPwVljEi_N6SPol4sGc,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_g7bR4LcgqJNxeTkCjHNUesiI96lbXDWGXsf_tkY0H7A,
  navigation_repaint_client_36099QVCT1dyZn230ABc_iWTnxuqDvJ45HYgibQSdxc,
  check_outdated_build_client_cofTII8VLlslUvJjCRU13U4OFIUEXK4UZMkw58qt_ac,
  chunk_reload_client_p0_NtiUQm0R_TizqEqZ10BDOLWfkMQRov314CqOP7w0,
  plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client__nkeDhMiIOTRi9G4mJQEdfK0ld70mVWal4BT2_lzhNo,
  plugin_nmEZsL6woq_99dOYLwIANJn6dURJtYM5CDpeuRUWIbc,
  plugin_client_unwbSlAJh6MovdrTGHeZT6yHRTE_s4SkmevwiKbqliA,
  slideovers_rBeH_l_W8_9oFpgqXGxIbYEBE_XeDRcz6hrfTO9aggM,
  modals_TLLiDLNceFzZlyV_8K9HQHoIJWVy8XNSsT_ueAtKUtw,
  colors_wstRWiKwhigqn4lOWAETagzOzpFn5ByHAaJo0W_CM9o,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_x7ErcjVqPG507X9B7TARgWnEOyuSD3GVRAN7UATfcnw,
  siteConfig_1q71eE_kXhO3rtT7EifVerkS1F3iJSjMFjFsGlCJxdM,
  inferSeoMetaPlugin__rygPpTQTHD6ZwSV_Pxl78zXBS_RcSFU9gDuv2HYrDs,
  titles_f7SMVJUHee5kXp7l0HP_qLKf0r_SzTKAWIZG0Aa0QrE,
  defaultsWaitI18n_WHL63kyutrL1KokBLzQ6hPP8dAmuF7HhKDtmrqYJVyU,
  i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY,
  switch_locale_path_ssr_o0tunxvYgtkZ_rniqpraeAu4gKWSs22WMG3vqjfgWuw,
  route_locale_detect_kPZiu5t37WxV9hrlElTF8BRy3io9HhsgppGCLuEM5CU,
  i18n_olN9U9w58EtSfjVnmP5F214841AKWqne_1Bpwa_UjWY,
  nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0,
  plugin_QeCAqS5j7aNHBRWK1O6s70paWD_vaV2bKTipXYcQ7Yw,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  _0_routeRules_fqnkpUHFM4B2PIWwpSQ1TDoOJQzXJ9Gi5qh_QetP2GQ,
  ssg_detect_5q2wA_8rlMfFQRTUZLPuilXcKnulSek1qzlYTjLiDDw
]