<script lang="ts" setup>
const { isConsentGiven, acceptedAnalytics, acceptedAds, isModalActive } =
  useConsent()

function accept() {
  isConsentGiven.value = true
  acceptedAnalytics.value = true
  acceptedAds.value = true
}

function decline() {
  isConsentGiven.value = true
  acceptedAnalytics.value = false
  acceptedAds.value = false
}
</script>

<template>
  <div
    v-if="!isConsentGiven"
    class="fixed bottom-0 right-0 z-50 p-10 print:hidden"
  >
    <div
      v-if="!isConsentGiven"
      class="fixed bottom-0 right-0 w-full bg-neutral-100 text-black md:w-2/3 lg:w-1/2"
    >
      <div class="flex flex-col justify-between gap-5 p-10">
        <div>
          <slot name="bar">
            <h2 class="font-pragmatica-ext mb-2 text-2xl font-bold">
              {{ $t('cookies.title') }}
            </h2>
            <p>
              {{ $t('cookies.description') }}
            </p>
          </slot>
        </div>
        <div class="flex flex-wrap items-center gap-2">
          <button
            class="font-pragmatica-ext h-fit w-full bg-black p-3 text-sm font-bold text-white sm:w-fit"
            @click="accept()"
          >
            {{ $t('cookies.buttons.accept') }}
          </button>
          <button
            class="font-pragmatica-ext h-fit w-full bg-neutral-200 p-3 text-sm font-bold text-black sm:w-fit"
            @click="decline()"
          >
            {{ $t('cookies.buttons.decline') }}
          </button>
          <button
            class="font-pragmatica-ext h-fit w-full bg-white p-3 text-sm font-bold text-black sm:w-fit"
            @click="isModalActive = true"
          >
            {{ $t('cookies.buttons.manage') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
