
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexshBDz_45PVIDO7fK4n7p46Jm1M3YujsJfpkC4CDvDxSdUMeta } from "/builds/ldseating/ldseating-frontend/pages/index.vue?macro=true";
import { default as aboutZMp4sFJoN_5LJl7L9Wg6SEAH_45vJtP1W_rikfeZvuXywMeta } from "/builds/ldseating/ldseating-frontend/pages/about.vue?macro=true";
import { default as loginxMGx994VUoBhTZ7biS8EK0kQlr2bB4hgdOkelVA5Ue4Meta } from "/builds/ldseating/ldseating-frontend/pages/login.vue?macro=true";
import { default as _91slug_93XcYQb1anZ0u83R2C1Sdr0iGWU8XNvZrppJrDdS1aMjYMeta } from "/builds/ldseating/ldseating-frontend/pages/[slug].vue?macro=true";
import { default as orderspQoVQl0Mt8CFR3_45caX09_45skXdXL8Ik3lz45sEb9AUl4Meta } from "/builds/ldseating/ldseating-frontend/pages/orders.vue?macro=true";
import { default as searchdT8GsUAlC8s3Yhy8t7CL9OlALn8_45Qy_fai_4525xgTsDMMeta } from "/builds/ldseating/ldseating-frontend/pages/search.vue?macro=true";
import { default as careerske0DHR4zY1gZpLXWAHnTEg4wl9S6l_Hnclb6DHebxwwMeta } from "/builds/ldseating/ldseating-frontend/pages/careers.vue?macro=true";
import { default as contactHOuHkhz5_45mSSlsAk8_45lSTDoTD2xUMZuK4AJFbkIV8Z0Meta } from "/builds/ldseating/ldseating-frontend/pages/contact.vue?macro=true";
import { default as downloadXDUZ3EVWcNyTxfY0AnIRednrhGqnnBDcz1gQ9Tu9LwwMeta } from "/builds/ldseating/ldseating-frontend/pages/download.vue?macro=true";
import { default as lookbookgz2zQkdzBZEQtWOVl3z0boTEnCho449slEiOsrEArikMeta } from "/builds/ldseating/ldseating-frontend/pages/lookbook.vue?macro=true";
import { default as _91id_93YpeNPvntsSwsZCwekDFXUtyZ2RLwNLoMzwqw0wkYd3oMeta } from "/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue?macro=true";
import { default as registerDUHTnIFJB7_WjsjZwQsKJBBMn10M1nZwNhUGsR46nCQMeta } from "/builds/ldseating/ldseating-frontend/pages/register.vue?macro=true";
import { default as designersr7IKa3lMHQfdZl0tElDqYLsMu6245ksQpEIHlf1UG_450Meta } from "/builds/ldseating/ldseating-frontend/pages/designers.vue?macro=true";
import { default as eu_45supportns_45CopPNo1n1KjmoMIZXY2Sup40wBpbM6_sXuS_oy_45AMeta } from "/builds/ldseating/ldseating-frontend/pages/eu-support.vue?macro=true";
import { default as indexMBIj777mJmUre58xNvdBx2QvpknTyLCIOY7XdYL_cXkMeta } from "/builds/ldseating/ldseating-frontend/pages/news/index.vue?macro=true";
import { default as _91nid_93yjbFzolXTOZFix_454E4_eEFl_45LnnCfgaucyFNTb5zLu8Meta } from "/builds/ldseating/ldseating-frontend/pages/node/[nid].vue?macro=true";
import { default as _91slug_93SKcosfhBFWIUOirdXTr7EZBF6bQGLpkC0db6JjT6PQ4Meta } from "/builds/ldseating/ldseating-frontend/pages/news/[slug].vue?macro=true";
import { default as indexKU_45cI7rgOSyL_45nrcSYvB_OlDvvMVrwWrxkplXHyYhEcMeta } from "/builds/ldseating/ldseating-frontend/pages/press/index.vue?macro=true";
import { default as style_45guidehzEVX0KOt1ElTjsed_45jCOL8AzNjq5ECs0ZNdkDeF2tkMeta } from "/builds/ldseating/ldseating-frontend/pages/style-guide.vue?macro=true";
import { default as _91slug_93XcfochgkXQI8B5R2ydq5mmcu1QAlMAm8UxNyxyLcDikMeta } from "/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue?macro=true";
import { default as _91slug_93fcUL5HNfyjecw5GYLf831XFcWdQjJjiMQrnAyj8g_45DkMeta } from "/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue?macro=true";
import { default as product_45linesCsmZEh4Rcd3B_idXUl4pbYSXyVS6bfkNCRNCA0KTje4Meta } from "/builds/ldseating/ldseating-frontend/pages/product-lines.vue?macro=true";
import { default as email_45verifiedThatueGCGLMHH_7BBY1U4FYQ5r7Jxr9uWepXkXxy6yUMeta } from "/builds/ldseating/ldseating-frontend/pages/email-verified.vue?macro=true";
import { default as privacy_45policyiIrYXxYDxVH_uebhBEcePsES6_UZxiDQ3pTTSbzcA_QMeta } from "/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue?macro=true";
import { default as indexLo7kg5JYhXTKavRXQi8jKI8mpTElVAd6SPy7EKefHT0Meta } from "/builds/ldseating/ldseating-frontend/pages/products/index.vue?macro=true";
import { default as sustainabilityooGBnfMvevLu8n6txWV0QGxwJ_45aCBXU5jTvxz2HG9loMeta } from "/builds/ldseating/ldseating-frontend/pages/sustainability.vue?macro=true";
import { default as _91slug_93Hh9Ld2Qvp37nHGuAUIV6LTjTTGO7ZbYuN_MUw8omdKIMeta } from "/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue?macro=true";
import { default as _91_46_46_46slug_932SNPnKz_45CNng3WDKaGvTm8EazEb_Wt8jfw1j1aMZ2UoMeta } from "/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue?macro=true";
import { default as _91slug_93IRRPhnEZLoLMrTiWKQdN6Xj64jdjmzh7E4fwJOfHdioMeta } from "/builds/ldseating/ldseating-frontend/pages/products/[slug].vue?macro=true";
import { default as customer_45serviceiaoNv4aYcaOAE1rFE8Q011YQWAFqqN1FfGz5nxwysdMMeta } from "/builds/ldseating/ldseating-frontend/pages/customer-service.vue?macro=true";
import { default as _91slug_93RDOeVMPTm5ab8pVU2l5cTRxbmBAyw7eW4Zg_45USHPi8kMeta } from "/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue?macro=true";
import { default as indexjupKKfEDoZP8sMdJpVSuloe3LKSlazKM8aqjl5dOICEMeta } from "/builds/ldseating/ldseating-frontend/pages/references/index.vue?macro=true";
import { default as sales_45conditionsrOzBt3aTyax4_DJARR7_45a4OOdWVvM15l8m5_45hk_45k9s0Meta } from "/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue?macro=true";
import { default as indexs46LXK4SwKSUPXPd0svGWKNYZ_mhPQiPaDtblX8UlyIMeta } from "/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue?macro=true";
import { default as _91code_935ZqleCOh75cvrzhVzwmVF1WV5K0k8YNd90TTrg_rQ2kMeta } from "/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue?macro=true";
import { default as _91slug_934TVuC9OOjJ15r3uy_eTXou9_yMTsXlvGX226HlGPkrMMeta } from "/builds/ldseating/ldseating-frontend/pages/products/category/[slug].vue?macro=true";
import { default as _91hash_93rMPRGvbB0MRyQQY9xa3yD1mdh_45qUprm3l9K9O_455Myg8Meta } from "/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue?macro=true";
import { default as _91nid_93E2Ec__GAY59VngyPxNkbdIKHUQK1UmrLIGlIchSA1pgMeta } from "/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue?macro=true";
import { default as component_45stubuIxHeytEC1UTPs9wVJXKDhFA8l8KxLPvPHL4PDwGDvgMeta } from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuIxHeytEC1UTPs9wVJXKDhFA8l8KxLPvPHL4PDwGDvg } from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue")
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about-us",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue")
  },
  {
    name: "about___de",
    path: "/de/ueber-uns",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/notre-societe",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/about.vue")
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/cs/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/login.vue")
  },
  {
    name: "slug___cs",
    path: "/cs/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[slug].vue")
  },
  {
    name: "orders___cs",
    path: "/cs/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue")
  },
  {
    name: "orders___de",
    path: "/de/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue")
  },
  {
    name: "orders___fr",
    path: "/fr/orders",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/orders.vue")
  },
  {
    name: "search___cs",
    path: "/cs/vyhledavani",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/search.vue")
  },
  {
    name: "careers___cs",
    path: "/cs/kariera",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue")
  },
  {
    name: "careers___de",
    path: "/de/karriere",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue")
  },
  {
    name: "careers___fr",
    path: "/fr/carri%C3%A8re",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/careers.vue")
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/contact.vue")
  },
  {
    name: "download___cs",
    path: "/cs/ke-stazeni",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue")
  },
  {
    name: "download___en",
    path: "/en/downloads",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue")
  },
  {
    name: "download___de",
    path: "/de/downloads",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue")
  },
  {
    name: "download___fr",
    path: "/fr/a-telecharger",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/download.vue")
  },
  {
    name: "lookbook___cs",
    path: "/cs/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue")
  },
  {
    name: "lookbook___en",
    path: "/en/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue")
  },
  {
    name: "lookbook___de",
    path: "/de/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue")
  },
  {
    name: "lookbook___fr",
    path: "/fr/lookbook",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/lookbook.vue")
  },
  {
    name: "pdf-id___cs",
    path: "/cs/pdf/:id()",
    meta: _91id_93YpeNPvntsSwsZCwekDFXUtyZ2RLwNLoMzwqw0wkYd3oMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue")
  },
  {
    name: "pdf-id___en",
    path: "/en/pdf/:id()",
    meta: _91id_93YpeNPvntsSwsZCwekDFXUtyZ2RLwNLoMzwqw0wkYd3oMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue")
  },
  {
    name: "pdf-id___de",
    path: "/de/pdf/:id()",
    meta: _91id_93YpeNPvntsSwsZCwekDFXUtyZ2RLwNLoMzwqw0wkYd3oMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue")
  },
  {
    name: "pdf-id___fr",
    path: "/fr/pdf/:id()",
    meta: _91id_93YpeNPvntsSwsZCwekDFXUtyZ2RLwNLoMzwqw0wkYd3oMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/pdf/[id].vue")
  },
  {
    name: "register___cs",
    path: "/cs/registrace",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/register.vue")
  },
  {
    name: "register___de",
    path: "/de/registrieren",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/enregistrer",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/register.vue")
  },
  {
    name: "designers___cs",
    path: "/cs/designeri",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue")
  },
  {
    name: "designers___en",
    path: "/en/designers",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue")
  },
  {
    name: "designers___de",
    path: "/de/designer",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue")
  },
  {
    name: "designers___fr",
    path: "/fr/createurs",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designers.vue")
  },
  {
    name: "eu-support___cs",
    path: "/cs/podpora-eu",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue")
  },
  {
    name: "eu-support___en",
    path: "/en/eu-support",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue")
  },
  {
    name: "eu-support___de",
    path: "/de/eu-foerderprogramme",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue")
  },
  {
    name: "eu-support___fr",
    path: "/fr/soutien-de-l-union-europeenne",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/eu-support.vue")
  },
  {
    name: "news___cs",
    path: "/cs/novinky",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue")
  },
  {
    name: "news___fr",
    path: "/fr/nouvelles",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/index.vue")
  },
  {
    name: "node-nid___cs",
    path: "/cs/node/:nid()",
    meta: _91nid_93yjbFzolXTOZFix_454E4_eEFl_45LnnCfgaucyFNTb5zLu8Meta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue")
  },
  {
    name: "node-nid___en",
    path: "/en/node/:nid()",
    meta: _91nid_93yjbFzolXTOZFix_454E4_eEFl_45LnnCfgaucyFNTb5zLu8Meta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue")
  },
  {
    name: "node-nid___de",
    path: "/de/node/:nid()",
    meta: _91nid_93yjbFzolXTOZFix_454E4_eEFl_45LnnCfgaucyFNTb5zLu8Meta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue")
  },
  {
    name: "node-nid___fr",
    path: "/fr/node/:nid()",
    meta: _91nid_93yjbFzolXTOZFix_454E4_eEFl_45LnnCfgaucyFNTb5zLu8Meta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/node/[nid].vue")
  },
  {
    name: "news-slug___cs",
    path: "/cs/novinky/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___de",
    path: "/de/nachrichten/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___fr",
    path: "/fr/nouvelles/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/news/[slug].vue")
  },
  {
    name: "press___cs",
    path: "/cs/pro-media",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue")
  },
  {
    name: "press___en",
    path: "/en/press",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue")
  },
  {
    name: "press___de",
    path: "/de/presse",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue")
  },
  {
    name: "press___fr",
    path: "/fr/presse",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/index.vue")
  },
  {
    name: "style-guide___cs",
    path: "/cs/style-guide",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/style-guide.vue")
  },
  {
    name: "style-guide___en",
    path: "/en/style-guide",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/style-guide.vue")
  },
  {
    name: "style-guide___de",
    path: "/de/style-guide",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/style-guide.vue")
  },
  {
    name: "style-guide___fr",
    path: "/fr/style-guide",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/style-guide.vue")
  },
  {
    name: "line-slug___cs",
    path: "/cs/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue")
  },
  {
    name: "line-slug___en",
    path: "/en/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue")
  },
  {
    name: "line-slug___de",
    path: "/de/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue")
  },
  {
    name: "line-slug___fr",
    path: "/fr/:line()/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/[line]/[slug].vue")
  },
  {
    name: "product-lines___cs",
    path: "/cs/produktove-rady",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue"),
    children: [
  {
    name: "product-lines-category-slug___cs",
    path: "kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue")
  }
]
  },
  {
    name: "product-lines___en",
    path: "/en/product-lines",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue"),
    children: [
  {
    name: "product-lines-category-slug___en",
    path: "category/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue")
  }
]
  },
  {
    name: "product-lines___de",
    path: "/de/produktlinien",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue"),
    children: [
  {
    name: "product-lines-category-slug___de",
    path: "kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue")
  }
]
  },
  {
    name: "product-lines___fr",
    path: "/fr/series-de-produits",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines.vue"),
    children: [
  {
    name: "product-lines-category-slug___fr",
    path: "categorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/product-lines/category/[slug].vue")
  }
]
  },
  {
    name: "email-verified___cs",
    path: "/cs/email-overen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue")
  },
  {
    name: "email-verified___en",
    path: "/en/email-verified",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue")
  },
  {
    name: "email-verified___de",
    path: "/de/email-uberpruft",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue")
  },
  {
    name: "email-verified___fr",
    path: "/fr/email-verifie",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/email-verified.vue")
  },
  {
    name: "privacy-policy___cs",
    path: "/cs/ochrana-osobnich-udaju",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/datenschutzbereich",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/politique-de-confidentialite",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/privacy-policy.vue")
  },
  {
    name: "products___cs",
    path: "/cs/produkty",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/index.vue")
  },
  {
    name: "products___en",
    path: "/en/products",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/index.vue")
  },
  {
    name: "products___de",
    path: "/de/produkte",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/index.vue")
  },
  {
    name: "products___fr",
    path: "/fr/produits",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/index.vue")
  },
  {
    name: "sustainability___cs",
    path: "/cs/udrzitelnost",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sustainability.vue")
  },
  {
    name: "sustainability___en",
    path: "/en/sustainability",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sustainability.vue")
  },
  {
    name: "sustainability___de",
    path: "/de/nachhaltigkeit",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sustainability.vue")
  },
  {
    name: "sustainability___fr",
    path: "/fr/durabilite",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sustainability.vue")
  },
  {
    name: "designer-slug___cs",
    path: "/cs/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue")
  },
  {
    name: "designer-slug___en",
    path: "/en/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue")
  },
  {
    name: "designer-slug___de",
    path: "/de/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue")
  },
  {
    name: "designer-slug___fr",
    path: "/fr/designer/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/designer/[slug].vue")
  },
  {
    name: "press-slug___cs",
    path: "/cs/pro-media/:slug(.*)*",
    meta: _91_46_46_46slug_932SNPnKz_45CNng3WDKaGvTm8EazEb_Wt8jfw1j1aMZ2UoMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue")
  },
  {
    name: "press-slug___en",
    path: "/en/press/:slug(.*)*",
    meta: _91_46_46_46slug_932SNPnKz_45CNng3WDKaGvTm8EazEb_Wt8jfw1j1aMZ2UoMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue")
  },
  {
    name: "press-slug___de",
    path: "/de/presse/:slug(.*)*",
    meta: _91_46_46_46slug_932SNPnKz_45CNng3WDKaGvTm8EazEb_Wt8jfw1j1aMZ2UoMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue")
  },
  {
    name: "press-slug___fr",
    path: "/fr/presse/:slug(.*)*",
    meta: _91_46_46_46slug_932SNPnKz_45CNng3WDKaGvTm8EazEb_Wt8jfw1j1aMZ2UoMeta || {},
    component: () => import("/builds/ldseating/ldseating-frontend/pages/press/[...slug].vue")
  },
  {
    name: "products-slug___cs",
    path: "/cs/produkty/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue")
  },
  {
    name: "products-slug___en",
    path: "/en/products/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue")
  },
  {
    name: "products-slug___de",
    path: "/de/produkte/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue")
  },
  {
    name: "products-slug___fr",
    path: "/fr/produits/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/[slug].vue")
  },
  {
    name: "customer-service___cs",
    path: "/cs/reklamace",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue")
  },
  {
    name: "customer-service___en",
    path: "/en/customer-service",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue")
  },
  {
    name: "customer-service___de",
    path: "/de/kundenservice",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue")
  },
  {
    name: "customer-service___fr",
    path: "/fr/reclamation",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/customer-service.vue")
  },
  {
    name: "reference-slug___cs",
    path: "/cs/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___en",
    path: "/en/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___de",
    path: "/de/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___fr",
    path: "/fr/reference/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/reference/[slug].vue")
  },
  {
    name: "references___cs",
    path: "/cs/reference",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue")
  },
  {
    name: "references___en",
    path: "/en/references",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue")
  },
  {
    name: "references___de",
    path: "/de/referenzen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue")
  },
  {
    name: "references___fr",
    path: "/fr/references",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/index.vue")
  },
  {
    name: "sales-conditions___cs",
    path: "/cs/obchodni-podminky",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue")
  },
  {
    name: "sales-conditions___en",
    path: "/en/sales-conditions",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue")
  },
  {
    name: "sales-conditions___de",
    path: "/de/geschaeftsbedingungen",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue")
  },
  {
    name: "sales-conditions___fr",
    path: "/fr/termes-et-conditions",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/sales-conditions.vue")
  },
  {
    name: "user-profile___cs",
    path: "/cs/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue")
  },
  {
    name: "user-profile___en",
    path: "/en/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue")
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue")
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/profile/index.vue")
  },
  {
    name: "user-activation-code___cs",
    path: "/cs/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue")
  },
  {
    name: "user-activation-code___en",
    path: "/en/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue")
  },
  {
    name: "user-activation-code___de",
    path: "/de/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue")
  },
  {
    name: "user-activation-code___fr",
    path: "/fr/user/activation/:code()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/activation/[code].vue")
  },
  {
    name: "products-category-slug___cs",
    path: "/cs/produkty/kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/category/[slug].vue")
  },
  {
    name: "products-category-slug___en",
    path: "/en/products/category/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/category/[slug].vue")
  },
  {
    name: "products-category-slug___de",
    path: "/de/produkte/kategorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/category/[slug].vue")
  },
  {
    name: "products-category-slug___fr",
    path: "/fr/produits/categorie/:slug()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/products/category/[slug].vue")
  },
  {
    name: "user-passwordReset-hash___cs",
    path: "/cs/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue")
  },
  {
    name: "user-passwordReset-hash___en",
    path: "/en/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue")
  },
  {
    name: "user-passwordReset-hash___de",
    path: "/de/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue")
  },
  {
    name: "user-passwordReset-hash___fr",
    path: "/fr/user/passwordReset/:hash()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/user/passwordReset/[hash].vue")
  },
  {
    name: "references-categories-nid___cs",
    path: "/cs/reference/kategorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue")
  },
  {
    name: "references-categories-nid___en",
    path: "/en/references/category/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue")
  },
  {
    name: "references-categories-nid___de",
    path: "/de/referenzen/kategorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue")
  },
  {
    name: "references-categories-nid___fr",
    path: "/fr/references/categorie/:nid()",
    component: () => import("/builds/ldseating/ldseating-frontend/pages/references/categories/[nid].vue")
  },
  {
    name: component_45stubuIxHeytEC1UTPs9wVJXKDhFA8l8KxLPvPHL4PDwGDvgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubuIxHeytEC1UTPs9wVJXKDhFA8l8KxLPvPHL4PDwGDvg
  }
]