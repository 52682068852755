import type {
  ProductSmartFilterDetail,
  ProductSmartFilterPagedResult,
  SlugTranslation,
} from '~/types/api'

export function createProductSmartFiltersRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'product-smart-filters'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      }
    }
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params,
    })
  }

  async function getCategoryFilters() {
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params: {
        filter: APIFilters.makeFilter([
          {
            [APIFilterOP.EQUALS]: {
              showInCategories: 1,
            },
          },
        ]),
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      },
    })
  }

  async function getFeaturedFilters() {
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params: {
        filter: APIFilters.makeFilter([
          {
            [APIFilterOP.EQUALS]: {
              showInRecommended: 1,
            },
          },
        ]),
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      },
    })
  }

  async function getSmartFilters() {
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params: {
        filter: APIFilters.makeFilter([
          {
            [APIFilterOP.EQUALS]: {
              showInSmartFilters: 1,
            },
          },
        ]),
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      },
    })
  }

  async function getAllPagination(params: {
    page: number
    itemsPerPage: number
    filter?: string
    sort?: string
  }) {
    if (!params.sort) {
      params.sort = APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' })
    }
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params,
    })
  }

  async function getBySlug(slug: string) {
    return get<ProductSmartFilterDetail>([DOMAIN, slug])
  }

  async function translateSlug(slug: string) {
    return get<SlugTranslation>([DOMAIN, slug, 'translate'])
  }

  return {
    getAll,
    getAllPagination,
    getBySlug,
    translateSlug,
    getCategoryFilters,
    getFeaturedFilters,
    getSmartFilters,
  }
}
