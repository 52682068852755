import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountActivation, LazyEmailVerification, LazyForgotPassword, LazyInformSalesman, LazyInformSalesmanAboutAutomaticActivation, LazyInformSalesmanAboutNewsletterSubscription, LazyInformSalesmanAboutOrder, LazyNeedHelp, LazyOrder, LazyRegistration, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AccountActivation", LazyAccountActivation],
["EmailVerification", LazyEmailVerification],
["ForgotPassword", LazyForgotPassword],
["InformSalesman", LazyInformSalesman],
["InformSalesmanAboutAutomaticActivation", LazyInformSalesmanAboutAutomaticActivation],
["InformSalesmanAboutNewsletterSubscription", LazyInformSalesmanAboutNewsletterSubscription],
["InformSalesmanAboutOrder", LazyInformSalesmanAboutOrder],
["NeedHelp", LazyNeedHelp],
["Order", LazyOrder],
["Registration", LazyRegistration],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
