<script setup lang="ts">
import type { BreadcrumbItemProps } from '@nuxtjs/seo/dist/runtime/nuxt/composables/useBreadcrumbItems'

const props = defineProps<{
  title: string
  description?: string
  slug?: string
  overrides?: (BreadcrumbItemProps | false | undefined)[]
}>()
</script>

<template>
  <div class="container mb-5 py-4 sm:pb-10 xl:py-10">
    <UiBreadcrumbs
      rounded
      class="mb-5 sm:mb-10"
      :slug="props.slug"
      :overrides="props.overrides"
      v-if="props.description"
    />
    <div
      class="flex flex-col flex-wrap justify-between gap-x-16 gap-y-5 md:flex-row md:items-start"
    >
      <div
        class="flex gap-x-5 sm:gap-x-10"
        :class="{
          'flex-col': props.description && props.description.length,
          'w-full flex-wrap items-start justify-between':
            !props.description || !props.description.length,
        }"
      >
        <UiBreadcrumbs
          rounded
          class="mb-5 sm:mb-10"
          :slug="props.slug"
          :overrides="props.overrides"
          v-if="!props.description"
        />
        <UiSkeletonText
          class="my-14 h-24 max-w-md lg:my-10 lg:h-36 lg:max-w-4xl"
          v-if="!props.title || !props.title.length"
        />
        <h1
          v-else
          class="font-pragmatica-ext text-4xl font-bold leading-tight sm:text-[calc(3.25rem+.5vw)] lg:text-7xl 2xl:text-8xl"
          v-html="props.title"
        ></h1>
      </div>
      <TypographyPerex v-if="props.description" class="max-w-2xl text-pretty">
        {{ props.description }}
      </TypographyPerex>
    </div>
  </div>
</template>
