
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en",
  "de",
  "fr"
]

export const localeLoaders = {
  cs: [
    {
      key: "locale_about_46cs_46ts_18044ffb",
      load: () => import("#nuxt-i18n/18044ffb" /* webpackChunkName: "locale_about_46cs_46ts_18044ffb" */),
      cache: true
    },
    {
      key: "locale_designers_46cs_46ts_c84cbf63",
      load: () => import("#nuxt-i18n/c84cbf63" /* webpackChunkName: "locale_designers_46cs_46ts_c84cbf63" */),
      cache: true
    },
    {
      key: "locale_homepage_46cs_46ts_84c2f44d",
      load: () => import("#nuxt-i18n/84c2f44d" /* webpackChunkName: "locale_homepage_46cs_46ts_84c2f44d" */),
      cache: true
    },
    {
      key: "locale_cookies_46cs_46ts_5132a42a",
      load: () => import("#nuxt-i18n/5132a42a" /* webpackChunkName: "locale_cookies_46cs_46ts_5132a42a" */),
      cache: true
    },
    {
      key: "locale_sustainability_46cs_46ts_1cd4c3ab",
      load: () => import("#nuxt-i18n/1cd4c3ab" /* webpackChunkName: "locale_sustainability_46cs_46ts_1cd4c3ab" */),
      cache: true
    },
    {
      key: "locale_baseCommon_46ts_4e4d773d",
      load: () => import("#nuxt-i18n/4e4d773d" /* webpackChunkName: "locale_baseCommon_46ts_4e4d773d" */),
      cache: true
    },
    {
      key: "locale_base_46cs_46ts_01f5b4c5",
      load: () => import("#nuxt-i18n/01f5b4c5" /* webpackChunkName: "locale_base_46cs_46ts_01f5b4c5" */),
      cache: true
    },
    {
      key: "locale_pages_46cs_46ts_bbd14b6e",
      load: () => import("#nuxt-i18n/bbd14b6e" /* webpackChunkName: "locale_pages_46cs_46ts_bbd14b6e" */),
      cache: true
    },
    {
      key: "locale_signup_46cs_46ts_90913909",
      load: () => import("#nuxt-i18n/90913909" /* webpackChunkName: "locale_signup_46cs_46ts_90913909" */),
      cache: true
    },
    {
      key: "locale_user_46cs_46ts_84a655b2",
      load: () => import("#nuxt-i18n/84a655b2" /* webpackChunkName: "locale_user_46cs_46ts_84a655b2" */),
      cache: true
    },
    {
      key: "locale_aboutCompany_46cs_46ts_75288c3a",
      load: () => import("#nuxt-i18n/75288c3a" /* webpackChunkName: "locale_aboutCompany_46cs_46ts_75288c3a" */),
      cache: true
    },
    {
      key: "locale_forCustomers_46cs_46ts_5b1afa59",
      load: () => import("#nuxt-i18n/5b1afa59" /* webpackChunkName: "locale_forCustomers_46cs_46ts_5b1afa59" */),
      cache: true
    },
    {
      key: "locale_emails_46cs_46ts_55a03402",
      load: () => import("#nuxt-i18n/55a03402" /* webpackChunkName: "locale_emails_46cs_46ts_55a03402" */),
      cache: true
    },
    {
      key: "locale_countries_46cs_46ts_39b2de34",
      load: () => import("#nuxt-i18n/39b2de34" /* webpackChunkName: "locale_countries_46cs_46ts_39b2de34" */),
      cache: true
    },
    {
      key: "locale_breadcrumb_46cs_46ts_86f41c20",
      load: () => import("#nuxt-i18n/86f41c20" /* webpackChunkName: "locale_breadcrumb_46cs_46ts_86f41c20" */),
      cache: true
    },
    {
      key: "locale_error_46cs_46ts_420822fe",
      load: () => import("#nuxt-i18n/420822fe" /* webpackChunkName: "locale_error_46cs_46ts_420822fe" */),
      cache: true
    },
    {
      key: "locale_configurator_46cs_46ts_c62c9022",
      load: () => import("#nuxt-i18n/c62c9022" /* webpackChunkName: "locale_configurator_46cs_46ts_c62c9022" */),
      cache: true
    },
    {
      key: "locale_euSupport_46cs_46ts_ae83a01a",
      load: () => import("#nuxt-i18n/ae83a01a" /* webpackChunkName: "locale_euSupport_46cs_46ts_ae83a01a" */),
      cache: true
    },
    {
      key: "locale_productline_46cs_46ts_a400bf34",
      load: () => import("#nuxt-i18n/a400bf34" /* webpackChunkName: "locale_productline_46cs_46ts_a400bf34" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_about_46en_46ts_2a94f4dd",
      load: () => import("#nuxt-i18n/2a94f4dd" /* webpackChunkName: "locale_about_46en_46ts_2a94f4dd" */),
      cache: true
    },
    {
      key: "locale_designers_46en_46ts_34e1b279",
      load: () => import("#nuxt-i18n/34e1b279" /* webpackChunkName: "locale_designers_46en_46ts_34e1b279" */),
      cache: true
    },
    {
      key: "locale_homepage_46en_46ts_c64c1c42",
      load: () => import("#nuxt-i18n/c64c1c42" /* webpackChunkName: "locale_homepage_46en_46ts_c64c1c42" */),
      cache: true
    },
    {
      key: "locale_cookies_46en_46ts_3980c872",
      load: () => import("#nuxt-i18n/3980c872" /* webpackChunkName: "locale_cookies_46en_46ts_3980c872" */),
      cache: true
    },
    {
      key: "locale_sustainability_46en_46ts_beea0c58",
      load: () => import("#nuxt-i18n/beea0c58" /* webpackChunkName: "locale_sustainability_46en_46ts_beea0c58" */),
      cache: true
    },
    {
      key: "locale_baseCommon_46ts_4e4d773d",
      load: () => import("#nuxt-i18n/4e4d773d" /* webpackChunkName: "locale_baseCommon_46ts_4e4d773d" */),
      cache: true
    },
    {
      key: "locale_base_46en_46ts_b1eed909",
      load: () => import("#nuxt-i18n/b1eed909" /* webpackChunkName: "locale_base_46en_46ts_b1eed909" */),
      cache: true
    },
    {
      key: "locale_pages_46en_46ts_cdcb3d91",
      load: () => import("#nuxt-i18n/cdcb3d91" /* webpackChunkName: "locale_pages_46en_46ts_cdcb3d91" */),
      cache: true
    },
    {
      key: "locale_signup_46en_46ts_677cea3d",
      load: () => import("#nuxt-i18n/677cea3d" /* webpackChunkName: "locale_signup_46en_46ts_677cea3d" */),
      cache: true
    },
    {
      key: "locale_user_46en_46ts_e5537618",
      load: () => import("#nuxt-i18n/e5537618" /* webpackChunkName: "locale_user_46en_46ts_e5537618" */),
      cache: true
    },
    {
      key: "locale_aboutCompany_46en_46ts_a73b5b60",
      load: () => import("#nuxt-i18n/a73b5b60" /* webpackChunkName: "locale_aboutCompany_46en_46ts_a73b5b60" */),
      cache: true
    },
    {
      key: "locale_forCustomers_46en_46ts_84607d78",
      load: () => import("#nuxt-i18n/84607d78" /* webpackChunkName: "locale_forCustomers_46en_46ts_84607d78" */),
      cache: true
    },
    {
      key: "locale_emails_46en_46ts_df41d0ac",
      load: () => import("#nuxt-i18n/df41d0ac" /* webpackChunkName: "locale_emails_46en_46ts_df41d0ac" */),
      cache: true
    },
    {
      key: "locale_countries_46en_46ts_b88d8407",
      load: () => import("#nuxt-i18n/b88d8407" /* webpackChunkName: "locale_countries_46en_46ts_b88d8407" */),
      cache: true
    },
    {
      key: "locale_breadcrumb_46en_46ts_3a1e2526",
      load: () => import("#nuxt-i18n/3a1e2526" /* webpackChunkName: "locale_breadcrumb_46en_46ts_3a1e2526" */),
      cache: true
    },
    {
      key: "locale_error_46en_46ts_837270e7",
      load: () => import("#nuxt-i18n/837270e7" /* webpackChunkName: "locale_error_46en_46ts_837270e7" */),
      cache: true
    },
    {
      key: "locale_configurator_46en_46ts_a36e72b7",
      load: () => import("#nuxt-i18n/a36e72b7" /* webpackChunkName: "locale_configurator_46en_46ts_a36e72b7" */),
      cache: true
    },
    {
      key: "locale_euSupport_46en_46ts_6de4d758",
      load: () => import("#nuxt-i18n/6de4d758" /* webpackChunkName: "locale_euSupport_46en_46ts_6de4d758" */),
      cache: true
    },
    {
      key: "locale_productline_46en_46ts_1ab47973",
      load: () => import("#nuxt-i18n/1ab47973" /* webpackChunkName: "locale_productline_46en_46ts_1ab47973" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_about_46de_46ts_ab97f0b1",
      load: () => import("#nuxt-i18n/ab97f0b1" /* webpackChunkName: "locale_about_46de_46ts_ab97f0b1" */),
      cache: true
    },
    {
      key: "locale_designers_46de_46ts_844dfc22",
      load: () => import("#nuxt-i18n/844dfc22" /* webpackChunkName: "locale_designers_46de_46ts_844dfc22" */),
      cache: true
    },
    {
      key: "locale_homepage_46de_46ts_d5ec7492",
      load: () => import("#nuxt-i18n/d5ec7492" /* webpackChunkName: "locale_homepage_46de_46ts_d5ec7492" */),
      cache: true
    },
    {
      key: "locale_cookies_46de_46ts_f0ed499a",
      load: () => import("#nuxt-i18n/f0ed499a" /* webpackChunkName: "locale_cookies_46de_46ts_f0ed499a" */),
      cache: true
    },
    {
      key: "locale_sustainability_46de_46ts_fb4513ae",
      load: () => import("#nuxt-i18n/fb4513ae" /* webpackChunkName: "locale_sustainability_46de_46ts_fb4513ae" */),
      cache: true
    },
    {
      key: "locale_baseCommon_46ts_4e4d773d",
      load: () => import("#nuxt-i18n/4e4d773d" /* webpackChunkName: "locale_baseCommon_46ts_4e4d773d" */),
      cache: true
    },
    {
      key: "locale_base_46de_46ts_c2a2fa46",
      load: () => import("#nuxt-i18n/c2a2fa46" /* webpackChunkName: "locale_base_46de_46ts_c2a2fa46" */),
      cache: true
    },
    {
      key: "locale_pages_46de_46ts_d8193f85",
      load: () => import("#nuxt-i18n/d8193f85" /* webpackChunkName: "locale_pages_46de_46ts_d8193f85" */),
      cache: true
    },
    {
      key: "locale_signup_46de_46ts_3680cab4",
      load: () => import("#nuxt-i18n/3680cab4" /* webpackChunkName: "locale_signup_46de_46ts_3680cab4" */),
      cache: true
    },
    {
      key: "locale_user_46de_46ts_d6252ab9",
      load: () => import("#nuxt-i18n/d6252ab9" /* webpackChunkName: "locale_user_46de_46ts_d6252ab9" */),
      cache: true
    },
    {
      key: "locale_aboutCompany_46de_46ts_bd3e8cf5",
      load: () => import("#nuxt-i18n/bd3e8cf5" /* webpackChunkName: "locale_aboutCompany_46de_46ts_bd3e8cf5" */),
      cache: true
    },
    {
      key: "locale_forCustomers_46de_46ts_98eaf82b",
      load: () => import("#nuxt-i18n/98eaf82b" /* webpackChunkName: "locale_forCustomers_46de_46ts_98eaf82b" */),
      cache: true
    },
    {
      key: "locale_emails_46de_46ts_b5c4c146",
      load: () => import("#nuxt-i18n/b5c4c146" /* webpackChunkName: "locale_emails_46de_46ts_b5c4c146" */),
      cache: true
    },
    {
      key: "locale_countries_46de_46ts_ccefc2bf",
      load: () => import("#nuxt-i18n/ccefc2bf" /* webpackChunkName: "locale_countries_46de_46ts_ccefc2bf" */),
      cache: true
    },
    {
      key: "locale_breadcrumb_46de_46ts_91f1dcbf",
      load: () => import("#nuxt-i18n/91f1dcbf" /* webpackChunkName: "locale_breadcrumb_46de_46ts_91f1dcbf" */),
      cache: true
    },
    {
      key: "locale_error_46de_46ts_34211205",
      load: () => import("#nuxt-i18n/34211205" /* webpackChunkName: "locale_error_46de_46ts_34211205" */),
      cache: true
    },
    {
      key: "locale_configurator_46de_46ts_cee4323e",
      load: () => import("#nuxt-i18n/cee4323e" /* webpackChunkName: "locale_configurator_46de_46ts_cee4323e" */),
      cache: true
    },
    {
      key: "locale_euSupport_46de_46ts_617d2f1e",
      load: () => import("#nuxt-i18n/617d2f1e" /* webpackChunkName: "locale_euSupport_46de_46ts_617d2f1e" */),
      cache: true
    },
    {
      key: "locale_productline_46de_46ts_b8a1f0c6",
      load: () => import("#nuxt-i18n/b8a1f0c6" /* webpackChunkName: "locale_productline_46de_46ts_b8a1f0c6" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_about_46fr_46ts_7025507a",
      load: () => import("#nuxt-i18n/7025507a" /* webpackChunkName: "locale_about_46fr_46ts_7025507a" */),
      cache: true
    },
    {
      key: "locale_designers_46fr_46ts_fadc425a",
      load: () => import("#nuxt-i18n/fadc425a" /* webpackChunkName: "locale_designers_46fr_46ts_fadc425a" */),
      cache: true
    },
    {
      key: "locale_homepage_46fr_46ts_627c7afc",
      load: () => import("#nuxt-i18n/627c7afc" /* webpackChunkName: "locale_homepage_46fr_46ts_627c7afc" */),
      cache: true
    },
    {
      key: "locale_cookies_46fr_46ts_6e4f94b8",
      load: () => import("#nuxt-i18n/6e4f94b8" /* webpackChunkName: "locale_cookies_46fr_46ts_6e4f94b8" */),
      cache: true
    },
    {
      key: "locale_sustainability_46fr_46ts_8e010e26",
      load: () => import("#nuxt-i18n/8e010e26" /* webpackChunkName: "locale_sustainability_46fr_46ts_8e010e26" */),
      cache: true
    },
    {
      key: "locale_baseCommon_46ts_4e4d773d",
      load: () => import("#nuxt-i18n/4e4d773d" /* webpackChunkName: "locale_baseCommon_46ts_4e4d773d" */),
      cache: true
    },
    {
      key: "locale_base_46fr_46ts_1318780d",
      load: () => import("#nuxt-i18n/1318780d" /* webpackChunkName: "locale_base_46fr_46ts_1318780d" */),
      cache: true
    },
    {
      key: "locale_pages_46fr_46ts_cd9e0734",
      load: () => import("#nuxt-i18n/cd9e0734" /* webpackChunkName: "locale_pages_46fr_46ts_cd9e0734" */),
      cache: true
    },
    {
      key: "locale_signup_46fr_46ts_4ded4b1e",
      load: () => import("#nuxt-i18n/4ded4b1e" /* webpackChunkName: "locale_signup_46fr_46ts_4ded4b1e" */),
      cache: true
    },
    {
      key: "locale_user_46fr_46ts_98dc8ebb",
      load: () => import("#nuxt-i18n/98dc8ebb" /* webpackChunkName: "locale_user_46fr_46ts_98dc8ebb" */),
      cache: true
    },
    {
      key: "locale_aboutCompany_46fr_46ts_e9db72a7",
      load: () => import("#nuxt-i18n/e9db72a7" /* webpackChunkName: "locale_aboutCompany_46fr_46ts_e9db72a7" */),
      cache: true
    },
    {
      key: "locale_forCustomers_46fr_46ts_7e471162",
      load: () => import("#nuxt-i18n/7e471162" /* webpackChunkName: "locale_forCustomers_46fr_46ts_7e471162" */),
      cache: true
    },
    {
      key: "locale_emails_46fr_46ts_06272c66",
      load: () => import("#nuxt-i18n/06272c66" /* webpackChunkName: "locale_emails_46fr_46ts_06272c66" */),
      cache: true
    },
    {
      key: "locale_countries_46fr_46ts_9db89a2e",
      load: () => import("#nuxt-i18n/9db89a2e" /* webpackChunkName: "locale_countries_46fr_46ts_9db89a2e" */),
      cache: true
    },
    {
      key: "locale_breadcrumb_46fr_46ts_4f3a47c3",
      load: () => import("#nuxt-i18n/4f3a47c3" /* webpackChunkName: "locale_breadcrumb_46fr_46ts_4f3a47c3" */),
      cache: true
    },
    {
      key: "locale_error_46fr_46ts_24bb9dbe",
      load: () => import("#nuxt-i18n/24bb9dbe" /* webpackChunkName: "locale_error_46fr_46ts_24bb9dbe" */),
      cache: true
    },
    {
      key: "locale_configurator_46fr_46ts_2687b912",
      load: () => import("#nuxt-i18n/2687b912" /* webpackChunkName: "locale_configurator_46fr_46ts_2687b912" */),
      cache: true
    },
    {
      key: "locale_euSupport_46fr_46ts_6067e8d7",
      load: () => import("#nuxt-i18n/6067e8d7" /* webpackChunkName: "locale_euSupport_46fr_46ts_6067e8d7" */),
      cache: true
    },
    {
      key: "locale_productline_46fr_46ts_90dca57b",
      load: () => import("#nuxt-i18n/90dca57b" /* webpackChunkName: "locale_productline_46fr_46ts_90dca57b" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/533f2824" /* webpackChunkName: "config_i18n_46config_46ts_533f2824" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "./lang/localeDetector.ts",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "cs",
      language: "cs",
      name: "Čeština",
      files: [
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.cs.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.cs.ts"
      ]
    },
    {
      code: "en",
      language: "en",
      name: "English",
      files: [
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.en.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.en.ts"
      ]
    },
    {
      code: "de",
      language: "de",
      name: "Deutsch",
      files: [
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.de.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.de.ts"
      ]
    },
    {
      code: "fr",
      language: "fr",
      name: "Français",
      files: [
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.fr.ts",
        "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.fr.ts"
      ]
    }
  ],
  defaultLocale: "cs",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix",
  lazy: true,
  langDir: "./lang/translations",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "https://ldseating.com",
  customRoutes: "config",
  pages: {
    register: {
      cs: "/registrace",
      en: "/register",
      de: "/registrieren",
      fr: "/enregistrer"
    },
    search: {
      cs: "/vyhledavani",
      en: "/search",
      de: "/suche",
      fr: "/recherche"
    },
    products: {
      cs: "/produkty",
      en: "/products",
      de: "/produkte",
      fr: "/produits"
    },
    "products/[slug]": {
      cs: "/produkty/[slug]",
      en: "/products/[slug]",
      de: "/produkte/[slug]",
      fr: "/produits/[slug]"
    },
    "products/category/[slug]": {
      cs: "/produkty/kategorie/[slug]",
      en: "/products/category/[slug]",
      de: "/produkte/kategorie/[slug]",
      fr: "/produits/categorie/[slug]"
    },
    "product-lines": {
      cs: "/produktove-rady",
      en: "/product-lines",
      de: "/produktlinien",
      fr: "/series-de-produits"
    },
    "product-lines/category/[slug]": {
      cs: "/produktove-rady/kategorie/[slug]",
      en: "/product-lines/category/[slug]",
      de: "/produktlinien/kategorie/[slug]",
      fr: "/series-de-produits/categorie/[slug]"
    },
    "product-lines/[slug]": {
      cs: "/produktove-rady/[slug]",
      en: "/product-lines/[slug]",
      de: "/produktlinien/[slug]",
      fr: "/series-de-produits/[slug]"
    },
    designers: {
      cs: "/designeri",
      en: "/designers",
      de: "/designer",
      fr: "/createurs"
    },
    "designer/[slug]": {
      cs: "/designer/[slug]",
      en: "/designer/[slug]",
      de: "/designer/[slug]",
      fr: "/designer/[slug]"
    },
    "references/index": {
      cs: "/reference",
      en: "/references",
      de: "/referenzen",
      fr: "/references"
    },
    "references/categories/[nid]": {
      cs: "/reference/kategorie/[nid]",
      en: "/references/category/[nid]",
      de: "/referenzen/kategorie/[nid]",
      fr: "/references/categorie/[nid]"
    },
    "reference/[slug]": {
      cs: "/reference/[slug]",
      en: "/reference/[slug]",
      de: "/reference/[slug]",
      fr: "/reference/[slug]"
    },
    lookbook: {
      cs: "/lookbook",
      en: "/lookbook",
      de: "/lookbook",
      fr: "/lookbook"
    },
    download: {
      cs: "/ke-stazeni",
      en: "/downloads",
      de: "/downloads",
      fr: "/a-telecharger"
    },
    about: {
      cs: "/o-nas",
      en: "/about-us",
      de: "/ueber-uns",
      fr: "/notre-societe"
    },
    careers: {
      cs: "/kariera",
      en: "/careers",
      de: "/karriere",
      fr: "/carrière"
    },
    "press/index": {
      cs: "/pro-media",
      en: "/press",
      de: "/presse",
      fr: "/presse"
    },
    "press/[...slug]": {
      cs: "/pro-media/[...slug]",
      en: "/press/[...slug]",
      de: "/presse/[...slug]",
      fr: "/presse/[...slug]"
    },
    "email-verified": {
      cs: "/email-overen",
      en: "/email-verified",
      de: "/email-uberpruft",
      fr: "/email-verifie"
    },
    "news/index": {
      cs: "/novinky",
      en: "/news",
      de: "/nachrichten",
      fr: "/nouvelles"
    },
    "news/[slug]": {
      cs: "/novinky/[slug]",
      en: "/news/[slug]",
      de: "/nachrichten/[slug]",
      fr: "/nouvelles/[slug]"
    },
    "[slug]": {
      cs: "/[slug]",
      en: "/[slug]",
      de: "/[slug]",
      fr: "/[slug]"
    },
    contact: {
      cs: "/kontakt",
      en: "/contact",
      de: "/kontakt",
      fr: "/contact"
    },
    "privacy-policy": {
      cs: "/ochrana-osobnich-udaju",
      en: "/privacy-policy",
      de: "/datenschutzbereich",
      fr: "/politique-de-confidentialite"
    },
    "sales-conditions": {
      cs: "/obchodni-podminky",
      en: "/sales-conditions",
      de: "/geschaeftsbedingungen",
      fr: "/termes-et-conditions"
    },
    "customer-service": {
      cs: "/reklamace",
      en: "/customer-service",
      de: "/kundenservice",
      fr: "/reclamation"
    },
    "eu-support": {
      cs: "/podpora-eu",
      en: "/eu-support",
      de: "/eu-foerderprogramme",
      fr: "/soutien-de-l-union-europeenne"
    },
    sustainability: {
      cs: "/udrzitelnost",
      en: "/sustainability",
      de: "/nachhaltigkeit",
      fr: "/durabilite"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "cs",
    language: "cs",
    name: "Čeština",
    files: [
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.cs.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.cs.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    language: "en",
    name: "English",
    files: [
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.en.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.en.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    language: "de",
    name: "Deutsch",
    files: [
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.de.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.de.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    language: "fr",
    name: "Français",
    files: [
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/about.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/designers.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/homepage.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/cookies.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/sustainability.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/baseCommon.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/base.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/pages.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/signup.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/user.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/aboutCompany.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/forCustomers.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/emails.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/countries.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/breadcrumb.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/error.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/configurator.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/euSupport.fr.ts",
        cache: undefined
      },
      {
        path: "/builds/ldseating/ldseating-frontend/i18n/lang/translations/productline.fr.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/