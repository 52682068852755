<script lang="ts" setup>
const {
  isConsentGiven,
  acceptedAnalytics,
  acceptedAds,
  isModalActive,
  cookieGroups,
} = useConsent()

function accept() {
  isConsentGiven.value = true
  acceptedAnalytics.value = true
  acceptedAds.value = true
}

function decline() {
  isConsentGiven.value = true
  acceptedAnalytics.value = false
  acceptedAds.value = false
}

function close() {
  isModalActive.value = false
}
</script>

<template>
  <UiModal v-model="isModalActive">
    <div class="h-full">
      <div class="">
        <div class="flex flex-col gap-2.5 p-10">
          <slot name="modal" />
          <button
            class="absolute right-10 top-10 self-end"
            @click="isModalActive = false"
          >
            <IconClose />
          </button>
          <template v-for="cookieGroup in cookieGroups" :key="cookieGroup.name">
            <h2 class="font-pragmatica-ext text-2xl font-bold">
              {{ cookieGroup.name }}
            </h2>
            <ul class="mb-5 flex flex-col gap-5">
              <li v-for="cookie in cookieGroup.cookies" :key="cookie.name">
                <slot name="cookie" v-bind="{ cookie }">
                  <div class="flex flex-col justify-start gap-1">
                    <label class="font-bold uppercase" tabindex="0">
                      {{ cookie.name }}
                      <span
                        class="font-normal normal-case"
                        v-if="cookie.description"
                      >
                        {{ cookie.description }}
                      </span>
                    </label>
                    <div class="flex items-baseline gap-1">
                      <label>
                        <input
                          v-if="cookieGroup.cookieType === 'necessary'"
                          type="checkbox"
                          disabled
                          checked
                        />
                        <input
                          v-else
                          type="checkbox"
                          :checked="cookie.cookieRef.value"
                          @change="
                            cookie.cookieRef.value = !cookie.cookieRef.value
                          "
                        />
                        {{ cookie.name }}
                      </label>
                    </div>
                  </div>
                </slot>
              </li>
            </ul>
          </template>
          <div class="flex flex-wrap gap-2">
            <button
              class="font-pragmatica-ext w-full bg-black px-4 py-3 font-bold text-white sm:w-fit"
              @click="
                () => {
                  close()
                }
              "
            >
              {{ $t('cookies.buttons.save') }}
            </button>
            <button
              class="font-pragmatica-ext w-full bg-black px-4 py-3 font-bold text-white sm:w-fit"
              @click="
                () => {
                  accept()
                  close()
                }
              "
            >
              {{ $t('cookies.buttons.accept') }}
            </button>
            <button
              class="font-pragmatica-ext w-full bg-neutral-200 px-4 py-3 font-bold sm:w-fit"
              @click="
                () => {
                  decline()
                  close()
                }
              "
            >
              {{ $t('cookies.buttons.decline') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>
