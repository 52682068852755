<script setup lang="ts">
import type { ProductSetSearchPagedResult } from '~/types/api'
import type { GridSets } from '~/components/Products/ProductsGrid.vue'

const props = defineProps<{
  productSets: ProductSetSearchPagedResult
  short?: boolean
}>()

const items = computed(() => {
  const sets: GridSets = []
  props.productSets.items.forEach((set) => {
    sets.push({
      productLine: {
        title: set.productLineTitle,
        urlSlug: set.productLineUrlSlug,
      },
      nid: set.nid,
      title: set.title,
      urlSlug: set.urlSlug,
      image: {
        url: set.image?.url,
        imageRatio: set.image?.imageRatio,
      },
      minimalProductPrice: set.minimalProductPrice,
      currency: set.currency,
      readyForConfigurator: set.readyForConfigurator,
    })
  })
  return sets
})

const emit = defineEmits(['linkClicked'])
</script>

<template>
  <div class="pb-10 xl:pb-20">
    <h2 class="font-pragmatica-ext mb-8 text-2xl font-bold">
      {{ $t('base.search.productSets') }}
    </h2>
    <ProductsGrid
      v-if="items.length"
      :sets="props.short ? items.slice(0, 6) : items"
      dense
      @link-clicked="emit('linkClicked')"
    />
  </div>
</template>
