<script lang="ts" setup>
import type { BreadcrumbItemProps } from '@nuxtjs/seo/dist/runtime/nuxt/composables/useBreadcrumbItems'

interface BreadcrumbsProps {
  showAtRoot?: boolean
  white?: boolean
  rounded?: boolean
  slug?: string
  overrides?: (BreadcrumbItemProps | false | undefined)[]
  append?: (BreadcrumbItemProps | false | undefined)[]
}

const props = defineProps<BreadcrumbsProps>()

const route = useRoute()
const breadcrumbs = useBreadcrumbItems({
  overrides: props.overrides,
  append: props.append,
})
const slots = useSlots()
</script>

<template>
  <div class="flex w-fit flex-col justify-between sm:flex-row">
    <nav aria-label="Breadcrumb">
      <ul
        v-if="props.showAtRoot || breadcrumbs.length > 1"
        class="flex flex-wrap"
        :class="[
          { 'bg-white': props.white },
          { 'rounded-2xl bg-neutral-100 px-5 py-1': rounded },
        ]"
      >
        <template v-for="(item, key) in breadcrumbs" :key="key">
          <li class="max-w-full whitespace-nowrap">
            <slot
              name="breadcrumb"
              :to="item.to"
              :title="item.label"
              :last="key === breadcrumbs.length - 1"
              :first="key === 0"
            >
              <NuxtLink
                :to="item.to"
                class="max-w-full text-sm no-underline hover:underline"
                :class="{
                  '!cursor-default !no-underline': item.to === route.fullPath,
                }"
              >
                <span
                  v-if="props.slug && key === breadcrumbs.length - 1"
                  v-html="props.slug"
                />
                <span v-else>{{ item.label }}</span>
                <IconBreadcrumbArrow
                  v-if="key !== breadcrumbs.length - 1"
                  class="mx-2 inline"
                />
              </NuxtLink>
            </slot>
          </li>
        </template>
      </ul>
    </nav>
    <div v-if="slots['float-right']" class="mt-2 sm:mt-0">
      <slot name="float-right" />
    </div>
  </div>
</template>
