import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig()
  const router = useRouter()
  const { user } = useUser()
  const { vueApp } = nuxtApp

  if (!sentry.clientDsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      app: [vueApp],
      dsn: sentry.clientDsn,
      environment: sentry.environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // TODO: Accept Sentry headers on API
      tracePropagationTargets: [
        'localhost',
        'ldseating.jagu.dev',
        'new.ldseating.com',
        'ldseating.com',
        /^\//,
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    })

    if (user) {
      Sentry.setUser({
        id: user.uid,
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
      })
    }
  }
})
