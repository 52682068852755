<script setup lang="ts">
const props = defineProps<{
  loading?: boolean
  lineColor?: 'black' | 'white'
}>()
</script>

<template>
  <template v-if="props.loading">
    <UiSpinner />
  </template>
  <div v-else class="group relative">
    <slot />
    <div
      class="absolute bottom-[-1px] h-[1px] w-full transition-all duration-300 group-hover:bottom-[-8px]"
      :class="props.lineColor === 'white' ? 'bg-white' : 'bg-black'"
    />
  </div>
</template>
