import validate from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/ldseating/ldseating-frontend/middleware/auth.global.ts";
import redirect_45global from "/builds/ldseating/ldseating-frontend/middleware/redirect.global.ts";
import resolve_45domain_45global from "/builds/ldseating/ldseating-frontend/middleware/resolveDomain.global.ts";
import manifest_45route_45rule from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45global,
  resolve_45domain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  node: () => import("/builds/ldseating/ldseating-frontend/middleware/node.ts")
}