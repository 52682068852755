export const useScripts = () => {
  const { acceptedAnalytics } = useConsent()
  const { isLoggedIn } = toRefs(useUser())
  const { proxy } = useClarity()

  // TODO: Add other scripts here (e.g. Google Tag Manager, Smartsupp)

  watch(
    [acceptedAnalytics, isLoggedIn],
    async ([accepted, loggedIn]) => {
      if (accepted || loggedIn) {
        proxy.clarity('consent')
        // TODO: Remove when https://github.com/nuxt/scripts/issues/442 is solved
        if (window && window.clarity) {
          window.clarity('consent')
        }
      } else {
        proxy.clarity('consent', false)
        // TODO: Remove when https://github.com/nuxt/scripts/issues/442 is solved
        if (window && window.clarity) {
          window.clarity('consent', false)
        }
      }
    },
    { immediate: true }
  )
}
