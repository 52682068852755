<script lang="ts" setup>
const props = defineProps<{
  invert?: boolean
}>()
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.021"
    height="12.021"
    viewBox="0 0 12.021 12.021"
  >
    <g id="Zakladniprvky_Krizek" transform="translate(0.354 0.354)">
      <g
        id="Group_1204"
        data-name="Group 1204"
        transform="translate(5.656 -5.657) rotate(45)"
      >
        <path
          id="Path_182"
          data-name="Path 182"
          d="M0,0H16"
          transform="translate(8.001 0) rotate(90)"
          fill="none"
          :stroke="props.invert ? 'white' : '#000'"
          stroke-width="1"
        />
      </g>
      <path
        id="Path_688"
        data-name="Path 688"
        d="M0,0H16"
        transform="translate(0) rotate(45)"
        fill="none"
        :stroke="props.invert ? 'white' : '#000'"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
