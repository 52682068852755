import type {
  ProductLineSearchPagedResult,
  ProductSetSearchPagedResult,
} from '~/types/api'

export function createSearchRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'search'
  const DOMAIN_PRODUCT_LINES = 'product-lines'
  const DOMAIN_PRODUCT_SETS = 'product-sets'

  async function searchProductLines(fulltext: string) {
    const params = {
      query: fulltext,
    }
    return get<ProductLineSearchPagedResult>([DOMAIN_PRODUCT_LINES, DOMAIN], {
      params,
    })
  }

  async function searchProductSets(fulltext: string) {
    const params = {
      query: fulltext,
    }
    return get<ProductSetSearchPagedResult>([DOMAIN_PRODUCT_SETS, DOMAIN], {
      params,
    })
  }

  return {
    searchProductLines,
    searchProductSets,
  }
}
