<script setup lang="ts">
const props = defineProps<{
  phone?: boolean
}>()

function toggleSearch() {
  if (searching.value) {
    searching.value = false
  } else {
    searching.value = true
  }
}

const searching = defineModel<boolean>()
</script>

<template>
  <div class="flex cursor-pointer items-center gap-1" @click="toggleSearch">
    <template v-if="!props.phone">
      <UiAnimatedUnderlineText>
        <div class="text-sm" :class="{ 'text-xl': props.phone }">
          {{ $t('base.search.title') }}
        </div>
      </UiAnimatedUnderlineText>
    </template>
    <IconFind
      v-if="!searching"
      :class="props.phone ? 'text-3xl' : 'text-sm'"
    ></IconFind>
    <IconClose
      v-else
      :class="props.phone ? 'text-3xl' : 'text-sm'"
      class="-mr-[5px]"
    ></IconClose>
  </div>
</template>

<style scoped></style>
