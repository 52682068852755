import routerOptions0 from "/builds/ldseating/ldseating-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.85_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__id_atuxal3fdrcizxdzbmqldulwve/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/ldseating/ldseating-frontend/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}