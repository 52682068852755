<script setup lang="ts">
import type { ProductLineInList } from '~/types/api'

const props = defineProps<{
  productLine: ProductLineInList
  dark?: boolean
  dense?: boolean
}>()

const localePath = useLocalePath()

const { getResourceUrl, getImageSrcSet } = useResources()

const image = computed(() => props.productLine?.thumbnailImages?.[0])
const hoverImage = computed(() => props.productLine?.thumbnailImages?.[1])

const srcset = getImageSrcSet(image.value?.url)
const hoverSrcset = hoverImage.value
  ? getImageSrcSet(hoverImage.value?.url)
  : undefined
</script>

<template>
  <div class="p-3" :class="{ 'bg-neutral-100': props.dark }">
    <NuxtLink
      class="no-underline"
      :to="
        localePath({
          name: 'slug',
          params: { slug: props.productLine.urlSlug },
        })
      "
    >
      <div
        class="group relative m-0 block h-full select-none overflow-hidden p-3 hover:!bg-white"
      >
        <div :class="{ 'flex items-center gap-10 md:block': dense }">
          <div v-if="props.dense" class="font-pragmatica-ext text-xl font-bold">
            {{ productLine.title }}
          </div>
          <div v-if="!props.dense" class="transition-colors duration-300">
            <div>
              <div class="font-pragmatica-ext mb-4 text-2xl font-bold">
                {{ productLine.title }}
              </div>
            </div>
          </div>
          <div class="relative z-0 my-0 block w-full overflow-hidden md:mt-10">
            <img
              :src="getResourceUrl(image?.url + '/32x32/FIT')"
              alt=""
              title=""
              class="absolute bottom-0 left-0 top-0 z-0 h-full w-full border-0 p-[8%] blur-md group-hover:opacity-0"
              loading="eager"
              aria-hidden="true"
            />
            <img
              :alt="image?.metaAlt"
              :title="image?.metaTitle"
              class="absolute bottom-0 left-0 top-0 z-10 w-full border-0 group-hover:bg-white"
              :class="{ 'opacity-0 group-hover:opacity-100': hoverImage }"
              loading="lazy"
              sizes="(min-width: 768px) 992px, 100vw"
              :srcset="srcset"
              :src="getResourceUrl(image?.url + '/640x640/FIT')"
            />
            <img
              :height="hoverImage.imageHeight || 595"
              :width="hoverImage.imageWidth || 992"
              v-if="hoverImage"
              :alt="hoverImage?.metaAlt"
              :title="hoverImage?.metaTitle"
              class="relative bottom-0 left-0 top-0 z-10 w-full border-0 bg-neutral-100 opacity-0 group-hover:opacity-0"
              loading="lazy"
              sizes="(min-width: 768px) 992px, 100vw"
              :srcset="hoverSrcset"
              :src="getResourceUrl(hoverImage?.url + '/640x640/FIT')"
              onload="this.classList.remove('opacity-0')"
            />
          </div>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped></style>
