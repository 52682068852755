<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUser } from '@/stores/user'
import { userGroups } from '~/constants/userGroups'

const { getResourceUrl } = useResources()

const { t } = useI18n()
const { getStaticPageSlug } = useStaticPages()

const userStore = useUser()

const { user, isLoggedIn, userGroupId } = storeToRefs(userStore)

const localePath = useLocalePath()
const { locale } = useI18n()

const productsLink = {
  name: 'products',
  slug: undefined,
  labelPath: 'pages.products.label',
}

const links = computed(() => [
  { name: 'news', slug: undefined, labelPath: 'pages.news.label' },
  { name: 'references', slug: undefined, labelPath: 'pages.references.label' },
  { name: 'designers', slug: undefined, labelPath: 'pages.designers.label' },
  { name: 'download', slug: undefined, labelPath: 'pages.toDownload.label' },
  { name: 'about', slug: undefined, labelPath: 'pages.about.label' },
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.CONTACT),
    labelPath: 'pages.contact.label',
  },
])

const changingLang = ref(false)
const userDetailOpen = ref(false)
const priceSettingsOpen = ref(false)

const isOpen = ref(false)

const alternativeMenu = computed(() => {
  return changingLang.value || userDetailOpen.value || priceSettingsOpen.value
})

function toggleAlternativeMenu(currentValue: boolean, callback: Function) {
  changingLang.value = false
  userDetailOpen.value = false
  priceSettingsOpen.value = false

  if (!currentValue) {
    document.addEventListener('click', closeAlternativeMenu)
  }
  callback(currentValue)
}

function closeAlternativeMenu(event: Event) {
  if (!event.target?.closest('.alternative-menu')) {
    changingLang.value = false
    userDetailOpen.value = false
    priceSettingsOpen.value = false

    document.removeEventListener('click', closeAlternativeMenu)
  }
}

watch(isLoggedIn, (value) => {
  if (!value && userDetailOpen.value) {
    // hide logout
    toggleUserDetail()
  }
})

function toggleUserDetail() {
  toggleAlternativeMenu(
    userDetailOpen.value,
    (value: boolean) => (userDetailOpen.value = !value)
  )
}

function togglePriceSettings() {
  toggleAlternativeMenu(
    priceSettingsOpen.value,
    (value: boolean) => (priceSettingsOpen.value = !value)
  )
}

function toggleLangSelector() {
  toggleAlternativeMenu(
    changingLang.value,
    (value: boolean) => (changingLang.value = !value)
  )
}

const { currentRoute } = useRouter()
watch(
  () => currentRoute.value.fullPath,
  () => {
    isOpen.value = false
    toggleAlternativeMenu(false, () => {})
  }
)

onMounted(() => {
  window.onresize = checkMobileMenu
})

function checkMobileMenu() {
  if (isOpen.value && window.innerWidth > 1024) {
    isOpen.value = false
  }
}

const searching = ref(false)
const scrollY = ref(0)

watch(
  [isOpen, searching, changingLang],
  (
    [isOpenNew, searchingNew, changingLangNew],
    [isOpenOld, searchingOld, changingLangOld]
  ) => {
    if (searching.value && isOpen.value) {
      isOpen.value = false
    }

    if (searchingOld && changingLangNew) {
      searching.value = false
    }

    if (changingLangOld && searchingNew) {
      changingLang.value = false
    }

    if (isOpen.value || searching.value) {
      scrollY.value = window.scrollY
      document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
      // document.body.style.top = `-${scrollY.value}px`
    } else {
      document
        .getElementsByTagName('html')[0]
        .classList.remove('overflow-hidden')
      // window.scrollTo(0, scrollY.value)
    }
  }
)

const repository = createProductSmartFiltersRepository()

const showProducts = ref(false)
const chosenItem = ref<'featured' | 'ourProducts'>('featured')

const { data: smartFiltersData, status } = await useLazyAsyncData(
  'featuredSmartFilters_' + locale.value,
  () => repository.getFeaturedFilters()
)

const smartFilters = computed(() => smartFiltersData.value?.items || [])

watch(
  smartFilters,
  () => {
    if (!smartFilters.value.length) {
      chosenItem.value = 'ourProducts'
    }
  },
  {
    immediate: true,
  }
)

// const productLinesRepository = createProductLineRepository()

// const { data: topSeriesData } = await useLazyAsyncData('topSeries', () => {
//   return Promise.all(
//     topSeries.map((series) => {
//       return productLinesRepository.getLineBySlug(series.slug)
//     })
//   )
// })

const { data: categoriesData } = await useLazyAsyncData(
  'categorySmartFilters_' + locale.value,
  () => repository.getCategoryFilters()
)

const categories = computed(() => categoriesData.value?.items || [])

const followUsLinks = computed(() => [
  {
    link: 'https://www.facebook.com/LDSeating',
    label: 'Facebook',
    imageSrc: '/assets/front/images/icons/social/facebook.svg',
  },
  {
    link: 'https://www.instagram.com/ldseating/',
    label: 'Instagram',
    imageSrc: '/assets/front/images/icons/social/instagram.svg',
  },
  {
    link: 'https://cz.pinterest.com/ldseating/',
    label: 'Pinterest',
    imageSrc: '/assets/front/images/icons/social/pinterest.svg',
  },
  {
    link: 'https://www.linkedin.com/company/ldseating',
    label: 'LinkedIn',
    imageSrc: '/assets/front/images/icons/social/linkedin.svg',
  },
])
</script>

<template>
  <header class="container" role="banner">
    <div class="flex h-full max-w-full flex-row items-center justify-between">
      <div
        class="flex h-24 w-full items-center justify-between lg:h-40"
        :class="{ 'lg:w-max': searching }"
      >
        <NuxtLink
          aria-label="Homepage link"
          :to="localePath({ name: 'index' })"
        >
          <UiLDSLogo class="h-16 lg:h-24" />
        </NuxtLink>
        <div
          v-if="searching"
          class="absolute left-0 top-24 z-50 w-full bg-white lg:hidden"
        >
          <div class="container">
            <UiHeaderFulltextSearch @close="searching = false" />
          </div>
        </div>
        <div class="flex gap-5 xl:gap-4">
          <UiHeaderFulltextSearchBtn
            phone
            class="lg:hidden"
            v-model="searching"
          />
          <button
            class="flex items-center lg:hidden"
            aria-label="Menu button"
            @click="isOpen = !isOpen"
            v-if="!searching"
          >
            <IconHamburger v-if="!isOpen" />
            <IconClose v-else-if="!searching" />
          </button>
        </div>
      </div>
      <div v-if="searching" class="w-full px-20 max-lg:hidden">
        <UiHeaderFulltextSearch @close="searching = false" />
      </div>
      <div
        class="hidden h-20 w-full flex-col gap-4 lg:flex"
        :class="[
          { hidden: !isOpen },
          { '!items-start lg:h-[85px]': searching },
        ]"
      >
        <div
          v-if="!isOpen"
          class="alternative-menu ml-auto flex items-baseline gap-8 whitespace-nowrap"
        >
          <UiHeaderFulltextSearchBtn v-model="searching" />
          <LazyLoginButton v-show="!isLoggedIn" />
          <template v-if="isLoggedIn">
            <UiHeaderExpandableButton
              :label="(user?.firstName ?? '') + ' ' + (user?.lastName ?? '')"
              :active="userDetailOpen"
              @toggle="toggleUserDetail"
            />
            <UiHeaderExpandableButton
              v-if="userGroupId === userGroups.ADMIN"
              :label="t('base.header.priceSettings.title')"
              :active="priceSettingsOpen"
              @toggle="togglePriceSettings"
            />
          </template>
          <UiHeaderExpandableButton
            :label="locale"
            :active="changingLang"
            uppercase
            @toggle="toggleLangSelector"
          />
        </div>
        <div v-if="!isOpen" class="h-full"></div>
        <div
          v-if="!searching"
          class="alternative-menu flex flex-col items-baseline justify-start gap-3 lg:flex-row lg:justify-end xl:gap-6 xl:text-lg"
          :class="{ 'text-xl': isOpen }"
        >
          <template v-if="alternativeMenu">
            <LazyUiHeaderUserMenu v-if="userDetailOpen" />
            <LazyUiHeaderPriceSettings v-if="priceSettingsOpen" />
            <LazyUiHeaderLangSelector v-if="changingLang" />
          </template>
          <template v-else>
            <LazyUiProductLinesDropdown />
            <LazyUiHeaderLinkButton
              v-for="(link, index) in links"
              :key="index"
              :link="link"
            />
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="isOpen"
      class="absolute left-0 z-50 h-[calc(100svh-6rem)] w-full overflow-x-auto bg-white"
    >
      <div class="container flex h-full flex-col justify-between pb-5">
        <div>
          <div
            class="mb-8 flex flex-col items-end gap-5 text-right"
            v-if="!showProducts"
          >
            <div class="group font-bold transition duration-300 xl:text-lg">
              <button @click="showProducts = true">
                <div class="flex items-center">
                  <span class="whitespace-nowrap">
                    {{ t('base.products.products') }}
                  </span>
                  <IconDropdownArrow class="ml-1 -rotate-90" />
                </div>
              </button>
              <span
                class="-mt-1 block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
                :class="{
                  '!max-w-full': $route.path.includes(
                    localePath({ name: 'products' })
                  ),
                }"
              />
            </div>
            <UiHeaderLinkButton
              v-for="(link, index) in links"
              :key="index"
              :link="link"
            />
          </div>
          <div class="mb-8 flex flex-col items-end gap-5 text-right" v-else>
            <div class="flex w-full justify-between">
              <button @click="showProducts = false">
                <IconDropdownArrow class="ml-1 rotate-90" />
              </button>
              <div class="flex gap-2 text-lg font-bold">
                <button
                  :class="{ underline: chosenItem === 'featured' }"
                  @click="chosenItem = 'featured'"
                  v-if="smartFilters.length"
                >
                  {{ $t('base.header.featured') }}
                </button>
                <button
                  :class="{ underline: chosenItem === 'ourProducts' }"
                  @click="chosenItem = 'ourProducts'"
                >
                  {{ $t('base.header.ourProducts') }}
                </button>
              </div>
            </div>
            <div
              class="flex flex-col items-end gap-2.5"
              v-if="chosenItem === 'featured'"
            >
              <NuxtLink
                :to="
                  localePath({
                    name: 'products-slug',
                    params: { slug: smartFilter.urlSlug },
                  })
                "
                v-for="smartFilter in smartFilters"
                class="group/category font-bold no-underline transition duration-300"
              >
                <div class="w-fit">
                  {{ smartFilter.title }}
                  <span
                    class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
                  />
                </div>
              </NuxtLink>
            </div>
            <div
              class="flex flex-col items-end gap-2.5"
              v-if="chosenItem === 'ourProducts'"
            >
              <NuxtLink
                :to="
                  localePath({
                    name: 'products-slug',
                    params: { slug: category.urlSlug },
                  })
                "
                v-for="category in categories"
                class="group/category font-bold no-underline transition duration-300"
              >
                <div class="w-fit">
                  {{ category.title }}
                  <span
                    class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
                  />
                </div>
              </NuxtLink>
            </div>
          </div>
          <div class="mb-8 flex w-full items-baseline justify-between">
            <LazyLoginButton v-show="!isLoggedIn" />
            <template v-if="isLoggedIn">
              <UiHeaderExpandableButton
                :label="(user?.firstName ?? '') + ' ' + (user?.lastName ?? '')"
                :active="userDetailOpen"
                @toggle="toggleUserDetail"
              />
              <UiHeaderExpandableButton
                v-if="userGroupId === userGroups.ADMIN"
                :label="t('base.header.priceSettings.title')"
                :active="priceSettingsOpen"
                @toggle="togglePriceSettings"
              />
            </template>
            <div class="flex gap-2">
              <LazyUiHeaderLangSelector />
            </div>
          </div>
          <div class="flex flex-col gap-5">
            <NuxtLink
              :to="localePath({ name: 'products' })"
              class="group/products font-bold no-underline transition duration-300"
              @click="isOpen = false"
            >
              <UiButtonBlack class="w-full">
                {{ $t('base.products.productsLink') }}
              </UiButtonBlack>
            </NuxtLink>
            <NuxtLink
              :to="localePath({ name: 'product-lines' })"
              class="group/products font-bold no-underline transition duration-300"
              @click="isOpen = false"
            >
              <UiButtonBlackOutline class="w-full">
                {{ $t('base.header.seriesLink') }}
              </UiButtonBlackOutline>
            </NuxtLink>
          </div>
        </div>
        <div class="flex justify-between gap-5 py-10">
          <TypographyBaseBold>Sledujte nás</TypographyBaseBold>
          <div class="flex gap-2">
            <NuxtLink
              v-for="(link, index) in followUsLinks"
              :key="'follow-us-link-' + index"
              :to="link.link"
              target="_blank"
              class="d-inline-block align-middle no-underline"
            >
              <img
                :src="getResourceUrl(link.imageSrc)"
                height="24"
                class="h-6"
                alt="Social media icon"
                loading="lazy"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
