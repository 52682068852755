const isModalActive = ref(false)
const prefix = 'ldSeating-'

export function useConsent() {
  const { t } = useI18n()

  const isConsentGiven = useCookie(prefix + 'isConsentGiven', {
    default: () => false,
  })

  const acceptedAnalytics = useCookie(prefix + 'analytics', {
    default: () => false,
  })

  const acceptedAds = useCookie(prefix + 'ads', {
    default: () => false,
  })

  const cookieGroups = computed(() => [
    {
      name: t('cookies.cookieGroups.necessary'),
      cookieType: 'necessary',
      cookies: [
        {
          name: t('cookies.cookies.preference.name'),
          description: t('cookies.cookies.preference.description'),
          cookieRef: isConsentGiven,
        },
      ],
    },
    {
      name: t('cookies.cookieGroups.optional'),
      cookieType: 'optional',
      cookies: [
        {
          name: t('cookies.cookies.analytics.name'),
          description: t('cookies.cookies.analytics.description'),
          cookieRef: acceptedAnalytics,
        },
        {
          name: t('cookies.cookies.ads.name'),
          description: t('cookies.cookies.ads.description'),
          cookieRef: acceptedAds,
        },
      ],
    },
  ])

  return {
    isConsentGiven,
    acceptedAnalytics,
    acceptedAds,
    isModalActive,
    cookieGroups,
  }
}
