<script setup lang="ts">
const localePath = useLocalePath()

const { getResourceUrl } = useResources()
const { getStaticPageSlug } = useStaticPages()

const { isModalActive } = useConsent()

const aboutCompanyLinks = computed(() => [
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.CONTACT),
    labelPath: 'pages.contact.label',
  },
  { name: 'about', slug: undefined, labelPath: 'pages.about.label' },
  { name: 'careers', slug: undefined, labelPath: 'pages.careers.label' },
  { name: 'press', slug: undefined, labelPath: 'pages.press.label' },
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.EU_SUPPORT),
    labelPath: 'pages.eu-support.label',
  },
])

const forCustomersLinks = computed(() => [
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.CUSTOMER_SERVICE),
    labelPath: 'pages.customer-service.label',
  },
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.SALES_CONDITIONS),
    labelPath: 'pages.sales-conditions.label',
  },
  {
    name: 'slug',
    slug: getStaticPageSlug(staticPageKeys.PRIVACY_POLICY),
    labelPath: 'pages.privacy-policy.label',
  },
])

const followUsLinks = computed(() => [
  {
    link: 'https://www.facebook.com/LDSeating',
    label: 'Facebook',
    imageSrc: '/assets/front/images/icons/social/facebook.svg',
  },
  {
    link: 'https://www.instagram.com/ldseating/',
    label: 'Instagram',
    imageSrc: '/assets/front/images/icons/social/instagram.svg',
  },
  {
    link: 'https://cz.pinterest.com/ldseating/',
    label: 'Pinterest',
    imageSrc: '/assets/front/images/icons/social/pinterest.svg',
  },
  {
    link: 'https://www.linkedin.com/company/ldseating',
    label: 'LinkedIn',
    imageSrc: '/assets/front/images/icons/social/linkedin.svg',
  },
])

const currentYear = new Date().getFullYear()
</script>

<template>
  <footer class="container overflow-hidden pb-8 pt-16" role="contentinfo">
    <div>
      <div class="flex flex-wrap justify-between md:mb-8">
        <div class="mb-8 w-auto max-w-full flex-[0_0_auto] pr-4">
          <NuxtLink
            :to="localePath('/')"
            class="brand"
            rel="home"
            aria-label="Homepage link"
          >
            <UiLDSLogo class="h-28" />
          </NuxtLink>
        </div>
        <div class="mb-8 w-auto max-w-full flex-[0_0_auto]">
          <div class="flex flex-wrap">
            <div class="mb-8 w-full sm:w-1/2 md:w-auto">
              <div class="mr-10">
                <h2 class="mb-4 text-lg font-bold">
                  {{ $t('baseCommon.companyInfo.name') }}
                </h2>
                <address class="text-sm not-italic">
                  <p>
                    {{ $t('baseCommon.companyInfo.street') }}
                    <br />
                    {{ $t('baseCommon.companyInfo.city') }}
                    <br />
                    {{ $t('baseCommon.companyInfo.country') }}
                  </p>
                </address>
              </div>
            </div>
            <div class="mb-8 w-full sm:w-1/2 md:w-auto">
              <div class="mr-10">
                <h2 class="mb-4 text-lg font-bold">
                  {{ $t('base.footer.aboutCompany') }}
                </h2>
                <ul class="mb-0 text-sm">
                  <li v-for="link in aboutCompanyLinks" :key="link.link">
                    <NuxtLink
                      :to="
                        localePath({
                          name: link.name,
                          params: { slug: link.slug },
                        })
                      "
                      class="no-underline"
                    >
                      {{ $t(link.labelPath) }}
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-8 w-full sm:w-1/2 md:w-auto">
              <div class="mr-10">
                <h2 class="mb-4 text-lg font-bold">
                  {{ $t('base.footer.forCustomers') }}
                </h2>
                <ul class="mb-0 text-sm">
                  <li v-for="link in forCustomersLinks" :key="link.link">
                    <NuxtLink
                      :to="
                        localePath({
                          name: link.name,
                          params: { slug: link.slug },
                        })
                      "
                      class="no-underline"
                    >
                      {{ $t(link.labelPath) }}
                    </NuxtLink>
                  </li>
                  <li>
                    <button
                      class="cursor-pointer no-underline"
                      @click="isModalActive = true"
                    >
                      Update cookies preferences
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-8 w-full sm:w-1/2 md:w-auto">
              <div>
                <h2 class="mb-4 text-lg font-bold">
                  {{ $t('base.footer.followUs') }}
                </h2>
                <ul class="mb-0 text-sm">
                  <li
                    v-for="(link, index) in followUsLinks"
                    :key="'follow-us-link-' + index"
                    class="mb-2"
                  >
                    <small class="mr-1 inline-block align-middle">
                      <img
                        :src="getResourceUrl(link.imageSrc)"
                        height="24"
                        class="h-6"
                        alt="Social media icon"
                        loading="lazy"
                      />
                    </small>
                    <NuxtLink
                      :to="link.link"
                      target="_blank"
                      class="d-inline-block align-middle no-underline"
                    >
                      {{ link.label }}
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-between">
        <div class="col-12 col-sm-auto mb-4">
          <div class="m-n2 m-sm-n3">
            <span class="d-inline-block m-sm-3 m-2">
              <span
                class="font-weight-normal cursor-pointer"
                style="border-bottom-color: transparent"
              >
                {{ '© ' + currentYear + ' LD Seating s.r.o.' }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-12 col-sm-auto mb-4">
          <div class="-m-1 sm:-m-3">
            <span class="m-1 inline-block lowercase sm:m-3">
              <span class="font-weight-normal mr-2">Design</span>
              <NuxtLink
                to="https://petrkincl.info/"
                class="font-weight-normal inline-block cursor-pointer border-b border-black no-underline"
                target="_blank"
              >
                petrkincl.info
              </NuxtLink>
            </span>
            <span class="small m-1 inline-block lowercase sm:m-3">
              <span class="font-weight-normal mr-2">Development</span>

              <NuxtLink
                to="https://jagu.cz"
                class="font-weight-normal group mx-1 inline-block cursor-pointer border-black no-underline"
                target="_blank"
              >
                <span class="mr-1">
                  <svg
                    class="inline-block h-5 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55.56 42.033"
                  >
                    <g id="jagu_logo_rgb" transform="translate(688.944 17.191)">
                      <path
                        id="Path_1"
                        class="fill-black transition duration-300 group-hover:fill-[#F89815]"
                        data-name="Path 1"
                        d="M96.522,12.383a4.147,4.147,0,0,0-3.181-1.442c-1.991,1.19-3.456,4.3-4.143,6.432,0,.092-1.1,5.264-2.678,3.525-.71-1.556-.572-2.678-.938-5.722a4.632,4.632,0,0,0-2.335-3.822h0c-.732-.732-13.023-3.639-13.962,2.884A61.374,61.374,0,0,0,64.479,9.11c-1.671-1.671-3.021-1.945-4.028-1.762.275-2.953.275-5.4-.32-6.02a2.176,2.176,0,0,0-2.243-.32c-1.808.824-3.708,2.518-5.012.023C52.783.687,49.762-1.853,49.4,2.61c.252,2.2.847,11.97.847,11.97.023,4.12-5.63-.572-6-.618-3.41-1.053-3,1.35-3,2.632,0,.549.389,1.007,1.03,1.556a4.131,4.131,0,0,1,2.243,2.083c.526,1.3,5.4,3.891,6,4.12a10.16,10.16,0,0,0,2.724.732,10.907,10.907,0,0,0,1.007,2.769c1.007,1.762,7-.252,9.247-.252S67,29.343,68,29.595a2.308,2.308,0,0,0,1.282-.389l.206.137c3,1.511,7.759-1.991,7.759-1.991s-.5,9.75-1.991,9.5c-1.511-.252-3.5-4.257-5.264-1.762s-1.007,3.754.5,4.509,6.752,4.005,8.766,1.511c1.991-2.495,5.012-7.255,5.264-10.254.046-.618.023-1.785-.046-3.25,3.136,1.259,6.2,1.739,7.072,1.053,1.213-1.007,6.477-14.03,4.967-16.273"
                        transform="translate(-730.176 -17.192)"
                      />
                    </g>
                  </svg>
                </span>
                <span
                  class="font-weight-normal inline-block border-b border-black"
                >
                  jagu.cz
                </span>
              </NuxtLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
