<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggle'])

const arrowDown = ''
const arrowUp = '-scale-y-100'
const arrowIcon = ref(arrowDown)
const activeLocal = ref(false)

watch(
  () => props.active,
  (value) => {
    if (activeLocal.value !== value) {
      update()
    }
  }
)

function update() {
  if (arrowIcon.value === arrowDown) {
    arrowIcon.value = arrowUp
    activeLocal.value = true
  } else {
    arrowIcon.value = arrowDown
    activeLocal.value = false
  }
}

function handleClick() {
  update()
  emit('toggle')
}
</script>

<template>
  <button
    class="font-weight-normal cursor-pointer text-sm"
    :class="{ uppercase: uppercase }"
    @click="handleClick"
  >
    <UiAnimatedUnderlineText class="mr-1 inline">
      {{ label }}
    </UiAnimatedUnderlineText>
    <IconDropdownArrow class="inline" :class="arrowIcon" />
  </button>
</template>

<style scoped></style>
