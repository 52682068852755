import type { Redirect } from '~/types/api'
import { APIFilters } from '~/utils/APIFilters'

export function createRedirectsRepository() {
  const { $api } = useNuxtApp()
  const repository = baseRepository($api)

  const DOMAIN = 'redirects'

  return {
    async getAll() {
      return repository.get<Redirect[]>([DOMAIN], {
        params: {
          sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
        },
      })
    },
  }
}
