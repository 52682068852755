
import * as ipxRuntime$_86ZSAdpKDiKUAHkq8X_cJ4d5_45EdFxnjSiw0WXH43Uuk from '/builds/ldseating/ldseating-frontend/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ldseatingRuntime$_8tsPNFt2h2Ct1cEThwohlTTFqaQ9wv1LcjlRYemZRzw from '/builds/ldseating/ldseating-frontend/providers/ldseating.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ldseating",
  "domains": [
    "ldseating.jagu.dev",
    "new.ldseating.jagu.dev",
    "api.ldseating.jagu.dev",
    "ldseating.com",
    "new.ldseating.com",
    "api.ldseating.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$_86ZSAdpKDiKUAHkq8X_cJ4d5_45EdFxnjSiw0WXH43Uuk, defaults: {} },
  ['ldseating']: { provider: ldseatingRuntime$_8tsPNFt2h2Ct1cEThwohlTTFqaQ9wv1LcjlRYemZRzw, defaults: {"baseURL":"https://api.ldseating.jagu.dev"} }
}
        